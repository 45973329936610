import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { _ as _to_consumable_array } from "@swc/helpers/_/_to_consumable_array";
import moment from "moment";
export function base64ToBlob(base64) {
    var binaryString = window.atob(base64.split(",")[1]); // Remove base64 header
    var bytes = new Uint8Array(binaryString.length);
    for(var i = 0; i < binaryString.length; i++){
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([
        bytes
    ], {
        type: "application/pdf"
    }); // Specify the Blob type if known
}
export function generateRandomString(length) {
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for(var i = 0; i < length; i++){
        var randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
}
export function mapMstToOptions(msts) {
    return msts.map(function(item) {
        return {
            label: item.name,
            value: item.id
        };
    });
}
export function mapMstPrefixToOptions(msts) {
    return msts.map(function(item) {
        return {
            label: item.name,
            value: item.id,
            prefix: item.prefix,
            suffix: item.suffix
        };
    });
}
export function getSupplierFullName(supData) {
    if (supData && supData.dim_mst_business_type_id == 1) {
        return "".concat(supData.first_name, " ").concat(supData.last_name);
    }
    if (supData && supData.dim_mst_business_type_id == 2 && supData.dimMstCompanyPrefix) {
        var _supData_dimMstCompanyPrefix_suffix;
        return "".concat(supData.dimMstCompanyPrefix.prefix, " ").concat(supData.company_name, " ").concat((_supData_dimMstCompanyPrefix_suffix = supData.dimMstCompanyPrefix.suffix) !== null && _supData_dimMstCompanyPrefix_suffix !== void 0 ? _supData_dimMstCompanyPrefix_suffix : "");
    }
    return "-";
}
export function getSupplierBranch(supData) {
    if (supData) {
        var _supData_company_branch_code, _supData_company_branch_name;
        return "(".concat((_supData_company_branch_code = supData.company_branch_code) !== null && _supData_company_branch_code !== void 0 ? _supData_company_branch_code : "", " - ").concat((_supData_company_branch_name = supData.company_branch_name) !== null && _supData_company_branch_name !== void 0 ? _supData_company_branch_name : "ไม่มีชื่อสาขา", ")");
    }
    return "(-)";
}
export function getSupplierBranchPrintType(supData) {
    if (supData) {
        var _supData_company_branch_name, _supData_company_branch_code;
        return "(".concat((_supData_company_branch_name = supData.company_branch_name) !== null && _supData_company_branch_name !== void 0 ? _supData_company_branch_name : "ไม่มีชื่อสาขา", ") - ").concat((_supData_company_branch_code = supData.company_branch_code) !== null && _supData_company_branch_code !== void 0 ? _supData_company_branch_code : "");
    }
    return "(-)";
}
export function getSupplierFullAddress(supData) {
    if (supData) {
        return "".concat(supData.company_address, ", ").concat(supData.company_sub_district, ", ").concat(supData.company_district, ", ").concat(supData.company_province, ", ").concat(supData.company_postal_code);
    }
    return "-";
}
export function getSupplierMainContactFullName(supData) {
    if (supData && supData.main_contact_first_name && supData.main_contact_last_name) {
        return "".concat(supData.main_contact_first_name, " ").concat(supData.main_contact_last_name);
    }
    return "-";
}
export function getEmpFullNameTH(userData) {
    if (userData && userData.dimNewAlldernowEmp) {
        return "".concat(userData.dimNewAlldernowEmp.first_name_th, " ").concat(userData.dimNewAlldernowEmp.last_name_th);
    }
    return "-";
}
export function getEmpFullNameTHByEmpData(empData) {
    if (empData) {
        var prefix = empData.dimMstPrefix ? empData.dimMstPrefix.name + " " : "";
        return "".concat(prefix).concat(empData.first_name_th, " ").concat(empData.last_name_th);
    }
    return "-";
}
export function NumberFormat(number) {
    var decimal = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 2;
    var formattedNumber = number.toLocaleString("en-US", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
    });
    return formattedNumber;
}
export function NumberFormatFloat(number) {
    var decimal = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 2;
    if (isNaN(number)) {
        return 0;
    }
    number = parseFloat(number);
    return parseFloat(number.toFixed(decimal));
}
export function mappingProductPriceData(param) {
    var productPrices = param.productPrices, values = param.values, optionsWarehouse = param.optionsWarehouse, optionsMstVat = param.optionsMstVat;
    return productPrices.map(function(el) {
        // console.log(el);
        var warehouseID = null;
        var warehouseName = null;
        if (values.is_one_receiving_point) {
            warehouseID = values.dim_warehouse_id;
            var warehouse = optionsWarehouse.find(function(item) {
                return values.dim_warehouse_id === item.value;
            });
            warehouseName = warehouse ? warehouse.label : "";
        }
        var dim_mst_vat_id = null;
        var vat = 0;
        optionsMstVat.map(function(v) {
            if (el.dimProduct && el.dimProduct.vat === "ไม่มี VAT" && v.value === 1) {
                dim_mst_vat_id = v.value;
                vat = v.vat;
            }
            if (el.dimProduct && el.dimProduct.vat === "VAT 0%" && v.value === 2) {
                dim_mst_vat_id = v.value;
                vat = v.vat;
            }
            if (el.dimProduct && el.dimProduct.vat === "VAT 7%" && v.value === 3) {
                dim_mst_vat_id = v.value;
                vat = v.vat;
            }
        });
        return _object_spread_props(_object_spread({}, el), {
            productCode: el.dimProduct && el.dimProduct.sku ? el.dimProduct.sku : "-",
            productID: el.id,
            productName: el.dimProduct && el.dimProduct.name_th ? el.dimProduct.name_th : "-",
            productRatioUnit: el.ratio_unit,
            productContainer: el.container,
            productBarcode: el.barcode,
            sellPrice: el.price_1,
            productPrice: getUnitCost(el),
            productUnit: el.dimMasterUnit && el.dimMasterUnit.name ? el.dimMasterUnit.name : "-",
            productUnitID: el.dimMasterUnit && el.dimMasterUnit.id ? el.dimMasterUnit.id : "-",
            productOrdered: el.productOrdered,
            ums: el.ums,
            productStock: el.productStock,
            warehouseID: warehouseID,
            warehouseName: warehouseName,
            discountType: 1,
            discountPercent: 0,
            discount: 0,
            productQty: 1,
            edit: false,
            dim_mst_vat_id: dim_mst_vat_id,
            vat: vat,
            productCategory: el.dimProduct && el.dimProduct.dimMasterMainCategory ? el.dimProduct.dimMasterMainCategory.name : "-",
            productBrand: el.dimProduct && el.dimProduct.dimMasterBrand ? el.dimProduct.dimMasterBrand.name : "-"
        });
    });
}
export function mappingOptionNewItemPrCol(options) {
    return options.map(function(item) {
        var sortKey = "";
        switch(item.name_th){
            case "รหัสสินค้า":
                sortKey = "productCode";
                break;
            case "รายการ":
                sortKey = "productName";
                break;
            case "บาร์โค้ด":
                sortKey = "productBarcode";
                break;
            case "คลังจัดส่ง":
                sortKey = "warehouseName";
                break;
            case "หน่วย":
                sortKey = "productUnit";
                break;
            case "จำนวน":
                sortKey = "productQty";
                break;
            case "ราคา/หน่วย":
                sortKey = "productPrice";
                break;
            case "ประเภทส่วนลด":
                sortKey = "discountType";
                break;
            case "ส่วนลด/หน่วย":
                sortKey = "discount";
                break;
            case "ภาษี":
                sortKey = "vat";
                break;
            case "มูลค่าก่อนภาษี":
                sortKey = "valueBeforeVat";
                break;
            case "มูลค่ารวมภาษี":
                sortKey = "valueIncludingVat";
                break;
            default:
                break;
        }
        return {
            label: item.name_th,
            value: item.id,
            sortKey: sortKey
        };
    });
}
export function sortItemSeq(columnsorts, productRow) {
    var compareFunction = function(a, b) {
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = columnsorts[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var _step_value = _step.value, sortKey = _step_value.sortKey, _step_value_direction = _step_value.direction, direction = _step_value_direction === void 0 ? "desc" : _step_value_direction;
                if (!sortKey) {
                    continue;
                }
                var aValue = void 0, bValue = void 0;
                if (sortKey === "valueBeforeVat") {
                    aValue = (a.productPrice - a.discount) * a.productQty / (1 + a.vat * 0.01);
                    bValue = (b.productPrice - b.discount) * b.productQty / (1 + b.vat * 0.01);
                } else if (sortKey === "valueIncludingVat") {
                    aValue = (a.productPrice - a.discount) * a.productQty;
                    bValue = (b.productPrice - b.discount) * b.productQty;
                } else {
                    aValue = a[sortKey];
                    bValue = b[sortKey];
                }
                if (direction === "desc") {
                    var ref;
                    ref = [
                        bValue,
                        aValue
                    ], aValue = ref[0], bValue = ref[1], ref;
                }
                var comparisonResult = void 0;
                if (typeof aValue === "string" && typeof bValue === "string") {
                    comparisonResult = aValue.localeCompare(bValue);
                } else {
                    comparisonResult = aValue - bValue;
                }
                // If the current comparison results in a difference, return the result
                if (comparisonResult !== 0) {
                    return comparisonResult;
                }
            // If the current comparison is equal, continue to the next key
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
        // If all comparisons are equal, return 0 (no change in order)
        return 0;
    };
    // Sort the products array using the compare function
    var sortedProducts = _to_consumable_array(productRow).sort(compareFunction);
    return sortedProducts;
}
export function WDA(weight, sumPrice, sumDiscount, isVat) {
    weight = NumberFormatFloat(weight, 4);
    sumPrice = NumberFormatFloat(sumPrice, 4);
    sumDiscount = NumberFormatFloat(sumDiscount, 4);
    if (sumPrice == 0) {
        return 0;
    }
    var discount = weight / sumPrice * sumDiscount;
    if (isVat) {
        discount /= 1.07;
    }
    return NumberFormatFloat(weight - NumberFormatFloat(discount, 4), 4);
}
export function getDiscountValue(discountTypeID, sumPrice, discountValue) {
    return discountTypeID === 1 ? sumPrice * discountValue / 100 : discountValue;
}
export function calPriceByMstCategoryPriceTax(item, mstCatPriceID) {
    var isIncludeTax = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    item.productPrice = NumberFormatFloat(item.productPrice, 4);
    item.discount = NumberFormatFloat(item.discount, 4);
    var totalPrice = (item.productPrice - item.discount) * item.productQty;
    if (mstCatPriceID === 1) {
        totalPrice = totalPrice * (1 + item.vat * 0.01);
    }
    if (isIncludeTax) {
        totalPrice = totalPrice / (1 + item.vat * 0.01);
    }
    return NumberFormatFloat(totalPrice, 4);
}
export var mappingPrintItemsData = function(items) {
    var mappingData = items.map(function(v) {
        var quantity = v.quantity, unit_price = v.unit_price, ratio_unit = v.ratio_unit, remark = v.remark, total_price_before_vat = v.total_price_before_vat, discount_price = v.discount_price, dimProductPrice = v.dimProductPrice, dimMasterUnit = v.dimMasterUnit, dimMstVat = v.dimMstVat;
        return {
            barcode: dimProductPrice.barcode,
            supcode: dimProductPrice.dimProduct && dimProductPrice.dimProduct.supplier_code ? "( ".concat(dimProductPrice.dimProduct.supplier_code, " )") : "( - )",
            product: dimProductPrice.dimProduct ? dimProductPrice.dimProduct.name_th : "-",
            qty: quantity,
            unit: dimMasterUnit ? "".concat(dimMasterUnit.name) : "-",
            ratio_unit: ratio_unit ? "(1X".concat(ratio_unit, ")") : "(1X-)",
            price: unit_price,
            discount: discount_price,
            vat_code: dimMstVat.value > 0 ? "V" : "NV",
            price_before_tax: total_price_before_vat,
            note: remark
        };
    });
    return mappingData;
};
export var getUniqueWarehouseName = function(items) {
    var uniqueNames = new Set();
    items.forEach(function(v) {
        var dimWarehouse = v.dimWarehouse;
        if (dimWarehouse && dimWarehouse.name) {
            uniqueNames.add(dimWarehouse.name);
        }
    });
    return Array.from(uniqueNames);
};
export var isReadyToPrint = function(formDisabled, mstStatusID) {
    // console.log(mstStatusID);
    var passList = [
        2,
        3,
        4,
        5,
        6
    ];
    return passList.includes(mstStatusID) && formDisabled;
};
export var getUnitCost = function(item) {
    var _item_dimProduct, _item_dimProduct1, _item_dimNewPurchaseReqItem;
    if (!item) {
        return 0;
    }
    var isUnitMinSizeCost = item.ratio_unit == 1 && item.unit_id == ((_item_dimProduct = item.dimProduct) === null || _item_dimProduct === void 0 ? void 0 : _item_dimProduct.sub_unit_id);
    var isUnitMaxSizeCost = item.ratio_unit > 1 && item.unit_id == ((_item_dimProduct1 = item.dimProduct) === null || _item_dimProduct1 === void 0 ? void 0 : _item_dimProduct1.unit_id);
    // get lastprice from req_item status success
    // console.log(item);
    var lastPrice = ((_item_dimNewPurchaseReqItem = item.dimNewPurchaseReqItem) === null || _item_dimNewPurchaseReqItem === void 0 ? void 0 : _item_dimNewPurchaseReqItem.length) > 0 ? item.dimNewPurchaseReqItem[0].unit_price : 0;
    if (isUnitMinSizeCost) {
        var _item_dimProduct2;
        return lastPrice ? lastPrice : (_item_dimProduct2 = item.dimProduct) === null || _item_dimProduct2 === void 0 ? void 0 : _item_dimProduct2.sub_unit_cost;
    } else if (isUnitMaxSizeCost) {
        var _item_dimProduct3;
        return lastPrice ? lastPrice : (_item_dimProduct3 = item.dimProduct) === null || _item_dimProduct3 === void 0 ? void 0 : _item_dimProduct3.unit_cost;
    }
    return lastPrice;
};
export var isOrderOverDue = function(delivery_date, delivery_time) {
    var _delivery_time_split_map = _sliced_to_array(delivery_time.split(":").map(Number), 2), hours = _delivery_time_split_map[0], minutes = _delivery_time_split_map[1];
    var deliveryDateTime = moment(delivery_date).add(hours, "hours").add(minutes, "minutes");
    return moment().isAfter(deliveryDateTime);
};
export var getDayDiffFromNow = function(date) {
    if (!date) {
        return "-";
    }
    if (date) {
        var dateExpire = moment(date);
        var currentDate = moment();
        var dayDifference = dateExpire.diff(currentDate, "days");
        return dayDifference;
    }
};
