import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { nanoid } from "@reduxjs/toolkit";
import { getFullUrlPath } from "./HandleFileFunction";
import moment from "moment";
import { getEmpFullNameTHByEmpData } from "./HelperFunction";
export function getActionDisplay(param) {
    var formdisabled = param.formdisabled, dimMstNewStatus = param.dimMstNewStatus;
    var status1 = !formdisabled && (!dimMstNewStatus || dimMstNewStatus && dimMstNewStatus.id < 3 || dimMstNewStatus && dimMstNewStatus.id === 7);
    var status2 = !formdisabled && (!dimMstNewStatus || dimMstNewStatus && dimMstNewStatus.id !== 7);
    var status2_1 = !(dimMstNewStatus && dimMstNewStatus.id > 1);
    var status4 = !!(dimMstNewStatus && dimMstNewStatus.id > 1 && dimMstNewStatus.id < 3);
    var status7 = !!(dimMstNewStatus && dimMstNewStatus.id > 2);
    var statusDropdown4 = !dimMstNewStatus || dimMstNewStatus && dimMstNewStatus.id < 4;
    var statusDropdown4_1 = !(dimMstNewStatus && dimMstNewStatus.id > 1);
    var statusDropdown7 = !!(dimMstNewStatus && dimMstNewStatus.id > 1);
    var actionDisplay = {
        status1: status1,
        status2: status2,
        status2_1: status2_1,
        status4: status4,
        status7: status7,
        statusDropdown4: statusDropdown4,
        statusDropdown4_1: statusDropdown4_1,
        statusDropdown7: statusDropdown7
    };
    return actionDisplay;
}
export function isDisabledEdit(param) {
    var dimMstNewStatus = param.dimMstNewStatus;
    var disabledList = [
        5,
        6
    ];
    if (dimMstNewStatus && disabledList.includes(parseInt(dimMstNewStatus.id))) {
        return true;
    }
    return false;
}
export function mappingPo(param) {
    var poData = param.poData, setPdata = param.setPdata, setProductRow = param.setProductRow, setFormdisabled = param.setFormdisabled, columnsort = param.columnsort, setColumnsort = param.setColumnsort, setAttachments = param.setAttachments, setTagSelected = param.setTagSelected;
    var dataWithReplacements = Object.entries(poData).reduce(function(acc, param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
        acc[key] = value === null ? "" : value;
        return acc;
    }, {});
    if (dataWithReplacements.dimSupplierInformation) {
        var dimSupplierInformation = dataWithReplacements.dimSupplierInformation;
        var full_supplier_name = dataWithReplacements.full_supplier_name, full_supplier_address_name = dataWithReplacements.full_supplier_address_name;
        setPdata(function(prev) {
            return {
                id: dimSupplierInformation.id,
                dimGroupOfSupplierCode: dimSupplierInformation.dimGroupOfSupplier ? dimSupplierInformation.dimGroupOfSupplier.code : "-",
                full_supplier_name: full_supplier_name,
                initials: dimSupplierInformation.initials,
                company_branch_code: dimSupplierInformation.company_branch_code,
                company_branch_name: dimSupplierInformation.company_branch_name,
                full_supplier_address_name: full_supplier_address_name,
                company_phone_number: dimSupplierInformation.company_phone_number
            };
        });
    }
    if (dataWithReplacements.dimDocFilePo && dataWithReplacements.dimDocFilePo.length > 0) {
        var attchments = dataWithReplacements.dimDocFilePo.map(function(v, i) {
            var newId = nanoid();
            var type = v.original_file_name.split(".");
            var mappingData = {
                id: newId,
                docId: v.id,
                docFile: v.original_file_name,
                docFileType: type[type.length - 1],
                docFileData: "",
                docUrl: getFullUrlPath(v.file_path)
            };
            return mappingData;
        });
        setAttachments(attchments);
    }
    if (dataWithReplacements.dimTagPo && dataWithReplacements.dimTagPo.length > 0) {
        var mappingData = dataWithReplacements.dimTagPo.map(function(v) {
            return v.name;
        });
        setTagSelected(mappingData);
    }
    if (dataWithReplacements.dimNewPurchaseOrderItem && dataWithReplacements.dimNewPurchaseOrderItem.length > 0) {
        var productItems = dataWithReplacements.dimNewPurchaseOrderItem.map(function(v) {
            var _dimProductPrice_dimProduct, _dimProductPrice_dimProduct1, _dimProductPrice_dimProduct2;
            var dim_product_price_id = v.dim_product_price_id, quantity = v.quantity, unit_price = v.unit_price, unit_id = v.unit_id, ratio_unit = v.ratio_unit, container = v.container, dim_mst_discount_type_id = v.dim_mst_discount_type_id, remark = v.remark, discount_value = v.discount_value, discount_price = v.discount_price, dimWarehouse = v.dimWarehouse, dimProductPrice = v.dimProductPrice, dimMasterUnit = v.dimMasterUnit, dimMstVat = v.dimMstVat, dimNewPoSplitItem = v.dimNewPoSplitItem;
            // console.log(discount_price);
            var mappingData = {
                rowID: nanoid(),
                productID: dim_product_price_id,
                productCode: (_dimProductPrice_dimProduct = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct === void 0 ? void 0 : _dimProductPrice_dimProduct.sku,
                productName: (_dimProductPrice_dimProduct1 = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct1 === void 0 ? void 0 : _dimProductPrice_dimProduct1.name_th,
                productBarcode: dimProductPrice.barcode,
                productUnitID: unit_id,
                productRatioUnit: ratio_unit,
                productContainer: container,
                supplierCode: (_dimProductPrice_dimProduct2 = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct2 === void 0 ? void 0 : _dimProductPrice_dimProduct2.supplier_code,
                warehouseID: dimWarehouse.id,
                warehouseName: dimWarehouse.name,
                productUnit: dimMasterUnit ? dimMasterUnit.name : "-",
                productQty: quantity,
                productOrdered: dimNewPoSplitItem ? dimNewPoSplitItem.total_received_quantity : 0,
                ums: "",
                productStock: "",
                productPrice: unit_price,
                discountType: dim_mst_discount_type_id,
                discount: discount_price,
                discountPercent: discount_value,
                tax: "",
                price_before_tax: 0,
                dim_mst_vat_id: dimMstVat.id,
                vat: dimMstVat.value,
                note: remark,
                price_after_tax: 0,
                not_approve_reason: "",
                is_checked: false,
                edit: false
            };
            return mappingData;
        });
        dataWithReplacements.products = productItems;
        setProductRow(productItems);
    }
    if (dataWithReplacements.dimMstNewStatus && dataWithReplacements.dimMstNewStatus.id > 1) {
        setFormdisabled(true);
    }
    dataWithReplacements.dim_mst_new_status_id = "";
    dataWithReplacements.dim_new_item_po_sorts = [];
    if (dataWithReplacements.dimNewItemPoSort && dataWithReplacements.dimNewItemPoSort.length > 0) {
        var mappingCols = dataWithReplacements.dimNewItemPoSort.map(function(v) {
            return {
                id: v.dimMstNewItemPrCol.id,
                sortKey: v.dimMstNewItemPrCol.name
            };
        });
        while(mappingCols.length <= 3){
            mappingCols.push({
                id: "",
                sortKey: ""
            });
        }
        setColumnsort(_object_spread_props(_object_spread({}, columnsort), {
            columnsort: mappingCols
        }));
    }
    if (dataWithReplacements.dimNewGr && dataWithReplacements.dimNewGr.length > 0) {
        var dimNewGr = dataWithReplacements.dimNewGr;
        dataWithReplacements.grList = mappingGr(dimNewGr);
    }
    return dataWithReplacements;
}
export function mappingGr(grs) {
    var mappingGr = grs.map(function(item) {
        return {
            documentID: item.id,
            documentName: "ใบรับสินค้า",
            documentCode: item.doc_number,
            receiveDate: moment(item.doc_receive_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY"),
            warehouse: item.dimWarehouse.name,
            quantity: item.total_receive_quantity,
            netValue: item.grand_total_price,
            documentIssuer: getEmpFullNameTHByEmpData(item.createdNewGrEmp)
        };
    });
    return mappingGr;
}
